import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {observer} from "mobx-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";


@observer
class BreadCrumbs extends React.Component {

    render() {
        let language = this.props.i18n.language;
        const {t, breadCrumbData} = this.props;
        let breadCrumbs = breadCrumbData.map((breadCrumb, index) => (
            <span key={index + breadCrumb.url}
                  itemProp="itemListElement" itemScope
                  itemType="http://schema.org/ListItem">
                <a
                    itemProp="item" href={`/${language + breadCrumb.url}`}
                    title={t(breadCrumb.title)}>
                <span itemProp="name" style={{"color": "grey"}}>
                    {t(breadCrumb.title)}
                </span>
                </a>
                  <meta itemProp="position" content={index + 2}/>
                {index === (breadCrumbData.length - 1) ? null : <Icon className="icon" name="chevron right"/>}
            </span>
        ));


        return (
            <div className="bread-crumb">
                <p itemScope itemType="http://schema.org/BreadcrumbList">
                    <Icon name="home"/>
                    <span itemProp="itemListElement" itemScope
                          itemType="http://schema.org/ListItem">
                        <a
                            itemProp="item"
                            href={`/${language}/`}
                            title={t("breadCrumbs.startSiteTitle")}>
                            <span itemProp="name" style={{"color": "grey"}}>
                                {t("titles.home")}
                            </span>
                            <meta itemProp="position" content="1"/>
                        </a>
                    </span> {breadCrumbData.length === 0 ? null :
                    <Icon className="icon" name="chevron right"/>}
                    {breadCrumbs}
                </p>
            </div>
        );
    }
}


BreadCrumbs.propTypes = {
    breadCrumbData: PropTypes.array
};

BreadCrumbs.defaultProps = {
    breadCrumbData: []
};

export default withTranslation()(BreadCrumbs)